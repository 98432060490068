import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';

import Layout from '../Components/Core/Layout';
import SEO from '../Components/Core/Seo';
// import PageBuilder from '../Components/Core/PageBuilder';

const pageTemplate = {};

const FourOhFourPage = ({
  intl,
  location,
  data: {
    contentfulPage: {
      title,
      metaDescription: { metaDescription },
      // header,
      // sections,
      // footer,
    },
  },
}) => {
  return (
    <Layout header={null} footer={null}>
      <SEO
        title={title}
        description={metaDescription}
        lang={intl.locale}
        location={location}
      />
      <div>
        <h1>404 Under maintenance</h1>
      </div>
    </Layout>
  );
};

FourOhFourPage.propTypes = {
  intl: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

export const query = graphql`
  query FourOhFourPage($locale: String = "en-CA") {
    contentfulPage(
      contentful_id: { eq: "6jEKokATZV7LXbfxnQX0Rq" }
      node_locale: { eq: $locale }
    ) {
      id
      node_locale
      title
      metaDescription {
        metaDescription
      }
      ...default_header
      ...default_footer
    }
  }
`;

export default injectIntl(FourOhFourPage);
